<template>
  <section class="section">
    <div class="section-header">
      <h1>Ordering</h1>
    </div>
    <div class="section-body" ref="formProducts">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>Venue</label>
            <select
              name=""
              id=""
              class="form-control"
              v-model="profile.venue_id"
              :disabled="profile.venue_id"
            >
              <option value="">Select Venue</option>
              <option v-for="venue in venues" :key="venue.id" :value="venue.id">
                {{ venue.title }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label>Search</label>
            <input type="text" v-model="filter.search" class="form-control" />
          </div>
          <div class="form-row">
            <div class="col">
              <select-input
                :value.sync="filter.category_id"
                :options="data.categories"
              ></select-input>
            </div>
            <div class="col">
              <select-input
                :value.sync="filter.type_id"
                :options="data.types"
              ></select-input>
            </div>
          </div>
          <button class="btn btn-primary w-100 shadow-sm" @click="getData">
            Search
          </button>
        </div>
      </div>
      <div
        class="row mt-3"
        style="
          overflow: scroll;
          height: calc(100vh - 200px);
          overflow-x: hidden;
        "
      >
        <div class="col-12">
          <div class="row">
            <div
              class="col-3"
              data-toggle="modal"
              v-for="e in products"
              :key="e.id"
              :data-target="`#exampleModal-${e.id}`"
            >
              <!-- <div
                class="card border shadow-lg"
                :style="
                  'min-height: 125px;' +
                    (e.stock_venue <= 0 ? 'background-color: #00000025' : '')
                "
                @click="addToCart($event, e)"
              > -->
              <div class="card border shadow-lg" @click="addToCart($event, e)">
                <div
                  :class="{
                    'card-body': true,
                    'bg-primary-200': productIsSelected(e.id),
                  }"
                >
                  <div class="row">
                    <div class="col-4">
                      <div
                        class="card-body rounded"
                        :style="{
                          backgroundImage: `url(${e.image})`,
                          backgroundSize: 'contain',
                          backgroundPosition: 'center',
                        }"
                      ></div>
                    </div>
                    <div class="col-8">
                      <small
                        class="font-weight-bold text-uppercase"
                        style="
                          font-size: 0.75em;
                          height: calc(0.75em * 3 * 1.2);
                          -webkit-line-clamp: 2;
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          overflow: hidden;
                        "
                        >{{ e.name }}</small
                      >
                      <small class="font-italic" style="font-size: 0.7em">{{
                        e.category_name
                      }}</small>
                      <br />
                      <!-- <small v-if="e.stock_venue > 0">
                        Stock : {{ e.stock_venue }}
                      </small> -->
                      <!-- <small class="text-danger" v-else>Stock : Habis</small> -->
                      <br />
                      <small style="font-style: 0.8em"
                        >Rp. {{ formatPrice(e.price_venue) }}</small
                      >
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      :id="`exampleModal-${cart.id}`"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="false"
      v-for="(cart, cartIndex) in carts"
      :key="cart.id"
    >
      <div
        class="modal-dialog modal-dialog-centered modal-dialog-scrollable w-100"
        role="document"
      >
        <div class="modal-content">
          <div
            class="modal-header"
            :style="{
              backgroundImage: `url(${cart.image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '250px',
            }"
          >
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <h5>{{ cart.name }}</h5>
                <p>Additional</p>
              </div>
            </div>
            <div>
              <v-app>
                <v-carousel
                  v-model="cart.additional_page"
                  height="100%"
                  :show-arrows="true"
                  :show-arrows-on-hover="true"
                  progress-color="#000"
                  :continuous="false"
                  :hide-delimiter-background="true"
                >
                  <v-carousel-item
                    v-for="(item, itemIndex) in cart.additionals"
                    :key="itemIndex"
                  >
                    <v-sheet tile>
                      <v-row
                        v-for="(additional, additionalIndex) in item"
                        :key="additional.id"
                        style="
                          border-top: 1px #c8c8c8 solid;
                          border-bottom: 1px #c8c8c8 solid;
                        "
                      >
                        <div class="col-8">
                          <div class>{{ additional.product_name }}</div>
                          <small
                            >+
                            {{
                              formatPrice(additional.price_venue || 0)
                            }}</small
                          >
                        </div>
                        <div class="col-4">
                          <button
                            class="btn btn-primary mx-2"
                            @click="
                              removeAdditional(
                                $event,
                                cartIndex,
                                itemIndex,
                                additionalIndex
                              )
                            "
                          >
                            -
                          </button>
                          <small class="mx-1" v-text="additional.count"></small>
                          <button
                            class="btn btn-primary mx-2"
                            @click="
                              addAdditional(
                                $event,
                                cartIndex,
                                itemIndex,
                                additionalIndex
                              )
                            "
                          >
                            +
                          </button>
                        </div>
                      </v-row>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
              </v-app>
            </div>
          </div>
          <div class="modal-footer row">
            <div class="col">
              <button
                class="btn btn-primary mx-2"
                @mousedown="decreaseProductCount($event, cart.id)"
              >
                -
              </button>
              <small class="mx-1" v-text="cart.count"></small>
              <button
                class="btn btn-primary mx-2"
                @mousedown="increaseProductCount($event, cart.id)"
              >
                +
              </button>
            </div>
            <div class="col-auto text-right">
              <span class="font-weight-bold"
                >Rp. {{ formatPrice(cartPrice(cart)) }}</span
              >
            </div>
            <div class="col-12 text-right">
              <button
                type="button"
                class="btn btn-secondary mr-1"
                data-dismiss="modal"
                @click="removeFromCart($event, cart.id)"
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary ml-1"
                data-dismiss="modal"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.bg-primary-200 {
  background-color: #007bff40;
}
</style>

<script>
import {
  OrderRepository,
  ProductCategoryRepository,
  ProductRepository,
  ProductTypeRepository,
  ProfileRepository,
  VenueRepository,
} from '../../repositories/RepositoryFactory';
import swal from 'sweetalert';
import axios from 'axios';
import { apiGet } from '../../services/api';

export default {
  name: 'DashboardOrdering',
  data() {
    return {
      products: {},
      filter: {
        search: '',
        category_id: '',
        type_id: '',
      },
      data: {
        categories: [],
        types: [],
      },
      form: {
        customer_name: '',
        table_name: '',
        order_time: new Date().toDateString(),
        venue_id: '',
      },
      profile: [],
      venues: [],
      errors: [],
    };
  },
  mounted() {
    this.getVenue();
    ProfileRepository.get().then(({ data }) => {
      this.profile = data.data;
      if (data.data.venue_id) {
        this.form.venue_id = data.data.venue_id;
      }
    });
    ProductCategoryRepository.getForSelect().then(({ data }) => {
      this.data.categories = data.data;
    });
    ProductTypeRepository.getForSelect().then(({ data }) => {
      this.data.types = data.data;
    });
  },
  methods: {
    getVenue() {
      apiGet('venue/venue').then((result) => {
        this.venues = result.data.data;
      });
    },

    onDecode(result) {
      this.$noty.success('We Got User ID!');
      let formData = new FormData();
      formData.set('aplication_user_id', result);
      axios
        .post(this.$store.state.api + 'get_member_card', formData, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((result) => {
          this.$noty.success('We Got Your Member Code!');
          this.NameUser = result.data.data.name;
          if (result.data.data.nickname == '') {
            this.form.customer_name = '';
          } else {
            this.form.customer_name = result.data.data.nickname;
          }
          this.camera = false;
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
    onLoaded() {
      console.log('load');
    },
    getData() {
      this.products = this.$store.state.productsForOrder;
      this.form.venue_id = this.profile.venue_id;
      ProductRepository.getAll({
        search: this.filter.search,
        category_id: this.filter.category_id,
        type_id: this.filter.type_id,
        venue_id: this.profile.venue_id,
      })
        .then(({ data }) => {
          data = data.data.map((e) => {
            e.additionals.map((add) => {
              add.count = 0;
              return add;
            });
            if (e.additionals) {
              e.additionals = [e.additionals];
            } else {
              e.additionals = [];
            }
            return e;
          });
          this.$store.state.productsForOrder = this.products = data;
        })
        .catch((err) => {
          swal('Error!', err.response.data.message, 'error');
        });
    },
    addAdditional(event, cartIndex, itemIndex, additionalIndex) {
      let item = this.carts[cartIndex].additionals[itemIndex][additionalIndex];
      let value = item.count;
      let incremented = value + 1;

      this.$set(
        this.carts[cartIndex].additionals[itemIndex][additionalIndex],
        'count',
        incremented
      );
      this.$forceUpdate();
    },
    removeAdditional(event, cartIndex, itemIndex, additionalIndex) {
      let current_value =
        this.carts[cartIndex].additionals[itemIndex][additionalIndex].count;

      if (current_value > 0) {
        this.$set(
          this.carts[cartIndex].additionals[itemIndex][additionalIndex],
          'count',
          --current_value
        );
        this.$forceUpdate();
      }
    },
    increaseProductCount(event, productId) {
      let data = this.carts.filter((e) => e.id == productId)[0];
      let additionals = JSON.parse(
        JSON.stringify(data.additionals[data.additionals.length - 1])
      );
      additionals = additionals.map((e) => {
        e.count = 0;
        return e;
      });
      data.count++;
      data.additional_page++;
      data.additionals.push(additionals);
    },
    decreaseProductCount(event, productId) {
      let data = this.carts.filter((e) => e.id == productId)[0];
      data.count--;
      data.additional_page--;
      if (data.count <= 0) {
        return (this.carts = this.carts.filter((e) => e.id != productId));
      }
      data.additionals.pop();
      this.$forceUpdate();
    },

    resetCart() {
      this.carts = [];
    },
    toggleCamera() {
      this.camera = !this.camera;
    },

    focusForm() {
      this.$refs.formCustomerOrder.scrollIntoView();
    },
    focusProducts() {
      this.$refs.formProducts.scrollIntoView();
    },
    productIsSelected(id) {
      return this.carts.find((e, i) => e.id == id);
    },
    removeFromCart(event, productId) {
      this.carts = this.carts.filter((e) => e.id != productId);
    },
    addToCart(event, product) {
      // if (product.stock_venue <= 0) return;
      // if (this.carts.filter((e) => e.id == product.id).length == 0) {
      //   product.count = 1;
      //   product.unit_id = product.product_unit_sale[0].unit_id;
      //   let obj = Object.assign({}, product);
      //   this.carts.push(obj);
      // } else {
      //   this.carts = this.carts.filter((e) => e.id != product.id);
      // }
      if (this.carts.filter((e) => e.id == product.id).length == 0) {
        product.count = 1;
        // product.unit_id = product.product_unit_sale[0].unit_id;
        let obj = Object.assign({}, product);
        this.carts.push(obj);
      }
    },
    cartPrice(cart) {
      let additionals = 0;
      cart.additionals.forEach((e) => {
        e.map((item) => {
          if (item.count > 0) {
            additionals += item.price_venue * item.count;
          }
        });
      });
      return parseInt(cart.price_venue + additionals);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
  },
  computed: {
    currentCart() {
      return this.carts[this.carts.length - 1].additionals[
        this.carts[this.carts.length - 1].additionals.length - 1
      ];
    },
  },
};
</script>
